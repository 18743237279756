 const courseLabelType = {
  1:{
    text:'必修课'
  },
  2:{
    text:'选修课'
  },
  3:{
    text:'公开课'
  }
}
const courseSource ={
  1:{
    text:'项目',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/project-icon.png'
  },
  2:{
    text:'课程',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/course-icon.png'
  },
  4:{
    text:'任务',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/task-icon.png'
  }
}
export default {
  courseLabelType,
  courseSource
}